import React, {Component} from 'react';
import {Route, Switch} from 'react-router';

import Dashboard from './Dashboard';
import ProfilePage from './ProfilePage';
import servicePage from '../../servicos/components/service-page';
import routes from '../../../../routes';
import {ProtectedRoute} from './ProtectedRoute';

export class AllRoutes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/service/:id" component={servicePage} />
        <Route path="/profile" component={ProfilePage} />
        {routes.map((route, i) => (
          <ProtectedRoute key={i} {...route} />
        ))}
      </Switch>
    );
  }
}
