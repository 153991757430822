import React, {Component} from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../../../../AuthContext';
import {
  WEBSPEED_URL,
  WEBSPEED_CONTEXT_URI,
  JSF_URL,
} from '../../../../consts/constants';

class ServiceIFrame extends Component {
  static propTypes = {
    id: PropTypes.string,
    onLoad: PropTypes.func,
    servico: PropTypes.any,
  };

  componentDidMount() {
    if (this.iframe) {
      this.iframe.addEventListener('load', this.props.onLoad);
    }
  }

  render() {
    let url = null;
    console.log(JSON.stringify(this.props.servico, null, 2));
    // Páginas JSF ou Apex
    if (this.props.servico.plataformaServico === 'JSF') {
      url = `${JSF_URL}/${this.props.servico.contextoServico}${this.props.servico.urlServico}`;
    }
    // Páginas WEBSPEED
    else if (this.props.servico.plataformaServico === 'WEBSPEED') {
      const webspeedUrl = `${WEBSPEED_URL}${WEBSPEED_CONTEXT_URI}`;
      url = `${webspeedUrl}${this.props.servico.urlServico}`;
    } else if (this.props.servico.plataformaServico === 'APEX') {
      url = `${JSF_URL}/${this.props.servico.contextoServico}${this.props.servico.urlServico}`;
    }
    console.log(JSON.stringify(url, 2, null));
    if (url != null && this.props.servico.urlServico) {
      return (
        <div
          className={
            this.props.servico.plataformaServico === 'WEBSPEED'
              ? 'layout-content-webspeed'
              : 'layout-content'
          }>
          <iframe
            title={this.props.id}
            onLoad={this.props.onLoad}
            frameBorder="0"
            scrolling="auto"
            src={url}
            className="layout-content-iframe"
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

ServiceIFrame.contextType = AuthContext;

export default ServiceIFrame;
