import axios from 'axios';

const API_URL = '/api-rc/seguranca/permissao/historico';

class HistoricoApi {
  static getHistorico() {
    return new Promise((resolve, reject) =>
      axios
        .get(API_URL)
        .then(response => resolve(response.data))
        .catch(err => reject(err)),
    );
  }
}

export default HistoricoApi;
