import React from 'react';
import PropTypes from 'prop-types';

const ProfileArea = props => {
  const alocadoEmEmpresa =
    props.perfil.codEmpresaAlocado && props.perfil.codEmpresaAlocado !== 0 ? (
      <ul>
        <li>Empresa: {props.perfil.dscEmpresaAlocado}</li>
      </ul>
    ) : (
      ''
    );
  const alocadoEm =
    props.perfil.codOrgaoAlocado && props.perfil.codOrgaoAlocado !== 0 ? (
      <ul>
        <li>
          Filial: {props.perfil.codOrgaoAlocado} -{' '}
          {props.perfil.dscOrgaoAlocado}
        </li>
      </ul>
    ) : (
      ''
    );
  const emailUsuario = props.perfil.dscEmail && (
    <ul>
      <li>Email: {props.perfil.dscEmail}</li>
    </ul>
  );
  const emailPessoa = props.perfil.dscEmailPessoal && (
    <ul>
      <li>Email pessoal: {props.perfil.dscEmailPessoal}</li>
    </ul>
  );

  return (
    <div className="card">
      <h1>
        Perfil de {props.perfil.loginName}
        {props.perfil.dscNomePessoa != null
          ? ` - ${props.perfil.dscNomePessoa}`
          : ''}
      </h1>
      {emailUsuario}
      {emailPessoa}
      {alocadoEmEmpresa}
      {alocadoEm}
    </div>
  );
};

ProfileArea.propTypes = {
  perfil: PropTypes.any,
};

export default ProfileArea;
