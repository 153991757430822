import {isAtacadista, isZonta, isHomologa} from '..';

// Parâmetros do Keycloak
export const KEYCLOAK_TOKEN = 'kc_token';
export const KEYCLOAK_REFRESH_TOKEN = 'kc_refreshToken';

// URL do servidor JSF
export const JSF_URL = isAtacadista()
  ? process.env.REACT_APP_JSF_URL_GIGANTE
  : process.env.REACT_APP_JSF_URL_CONDOR;

// URL do servidor WebSpeed
export const WEBSPEED_URL = isAtacadista()
  ? process.env.REACT_APP_WEBSPEED_URL_GIGANTE
  : process.env.REACT_APP_WEBSPEED_URL_CONDOR;

export const WEBSPEED_CONTEXT_URI = isHomologa()
  ? process.env.REACT_APP_WEBSPEED_CONDOR_CONTEXT_URI_HMP
  : isZonta()
  ? process.env.REACT_APP_WEBSPEED_ZONTA_CONTEXT_URI
  : process.env.REACT_APP_WEBSPEED_CONDOR_CONTEXT_URI;

// URI da API para consultar sub-projetos
export const API_CHUNKS_URI = process.env.REACT_APP_API_CHUNKS_URI;
export const API_CHUNKS_URI_ACCESS =
  process.env.REACT_APP_API_CHUNKS_URI_ACCESS;
