/*
 * Polyfills obrigatórios para o React 16 funcionar
 */
// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
// import 'babel-polyfill';
// import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
import Keycloak from 'keycloak-js';
import Axios from 'axios';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'fullcalendar/dist/fullcalendar.css';
import './layout/layout.scss';
import * as Const from './consts/constants';
import Root from './apps/menu/root';
// import KcApi from './apps/menu/apis/kcApi';

/**
 * Multi-tenancy para o KC.
 */
export function isAtacadista() {
  return (
    window.location.hostname.includes('maxcontemporanea') ||
    window.location.hostname.includes('maxatacarejo') ||
    window.location.hostname.includes('giganteatacadista')
    // || window.location.hostname.includes("localhost") // Teste local
  );
}
export function isZonta() {
  return window.location.hostname.includes('zonta');
}
export function isHomologa() {
  console.log('Chamou isHomologa()');
  return window.location.hostname.includes('homologa');
}
export function isLocalhost() {
  console.log('Chamou isLocalhost()');
  return window.location.hostname.includes('localhost');
}

if (isAtacadista()) {
  document.title = 'Corporativo - Gigante Atacadista';
  (function() {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/favicon2.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  })();
} else {
  document.title = 'Corporativo - Condor Super Center Ltda';
  (function() {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/favicon1.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  })();
}

const kcCondor = {
  url: 'https://auth.condor.com.br/auth',
  realm: 'condor',
  clientId: 'web_app',
  resource: 'web_app',
  'public-client': true,
  'confidential-port': 0,
};

const kcCondorHmp = {
  url: 'https://auth.condor.com.br/auth',
  realm: 'condor',
  clientId: 'web_app',
  resource: 'web_app',
  'public-client': true,
  'confidential-port': 0,
};

// const kcContemporanea = {
//   url: 'https://auth.maxatacarejo.com.br/auth',
//   realm: 'contemporanea',
//   clientId: 'web_app',
//   resource: 'web_app',
//   'public-client': true,
//   'confidential-port': 0,
// };

const kc = new Keycloak(isHomologa() || isLocalhost() ? kcCondorHmp : kcCondor);
const token = sessionStorage.getItem(Const.KEYCLOAK_TOKEN);
const refreshToken = sessionStorage.getItem(Const.KEYCLOAK_REFRESH_TOKEN);

kc.init({
  onLoad: 'login-required',
  promiseType: 'native',
  token,
  refreshToken,
}).then(authenticated => {
  if (authenticated) {
    updateSessionStorage();
    ReactDOM.render(<Root authToken={kc} />, document.getElementById('root'));
  }
});

Axios.interceptors.request.use(config => {
  if (!config.url.endsWith('openid-connect/token/introspect')) {
    return kc
      .updateToken(70)
      .then(refreshed => {
        if (refreshed) {
          updateSessionStorage();
        }
        config.headers.Authorization = 'Bearer ' + kc.token;
        return Promise.resolve(config);
      })
      .catch(() => {
        kc.login();
      });
  } else {
    return Promise.resolve(config);
  }
});

const updateSessionStorage = () => {
  sessionStorage.setItem(Const.KEYCLOAK_TOKEN, kc.token);
  sessionStorage.setItem(Const.KEYCLOAK_REFRESH_TOKEN, kc.refreshToken);
};

// setInterval(() => {
//   if (kc.authenticated && kc.isTokenExpired()) {
//     const kcConf = isAtacadista() ? kcContemporanea : kcCondor;
//     KcApi.checkToken(
//       kcConf.url,
//       kcConf.realm,
//       kcConf.clientId,
//       kcConf.credentials && kcConf.credentials.secret
//         ? kcConf.credentials.secret
//         : null,
//       kc.refreshToken,
//     )
//       .then(resp => {
//         if (!resp.active) {
//           kc.logout();
//         }
//       })
//       .catch(() => {
//         kc.logout();
//       });
//   }
// }, 60 * 1000);

// registerServiceWorker();
