import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SplashScreen from './splashScreen';
import ErrorPage from './error-page';
import ServiceIFrame from './service-iframe';
import {loadServico} from '../apiCalls';
import ServicoApi from '../../apis/servicoApi';

class ServicePage extends Component {
  static propTypes = {
    getServico: PropTypes.any,
    servicoSelecionado: PropTypes.any,
    error: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      servicoSelecionado: null,
      error: null,
      isOk: false,
      path: props.location.pathname,
    };
    this.iFrameLoaded = this.iFrameLoaded.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    return {params: nextProps.match.params};
  }

  async componentDidMount() {
    try {
      await ServicoApi.checkAccess(this.state.path);
      this.setState({isOk: true});

      const response = await loadServico(this.state.params.id);
      this.setState({servicoSelecionado: response});
    } catch (error) {
      this.setState({error: error});
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.params.id !== prevState.params.id) {
      await ServicoApi.checkAccess(this.state.path);
      this.setState({isOk: true});

      this.setState({isLoading: true});
      try {
        const response = await loadServico(this.state.params.id);
        this.setState({servicoSelecionado: response});
      } catch (error) {
        this.setState({error: error, servicoSelecionado: null});
      }
    }
  }

  iFrameLoaded() {
    this.setState({isLoading: false});
  }

  render() {
    return this.state.isOk ? (
      <div className="ui-g">
        <div className="ui-g-12 ui-md-12">
          {this.state.isLoading && <SplashScreen />}
          {this.state.servicoSelecionado && (
            <ServiceIFrame
              id={this.state.params.id}
              onLoad={this.iFrameLoaded}
              servico={this.state.servicoSelecionado}
            />
          )}
          {this.state.error && (
            <ErrorPage
              id={this.state.params.id}
              onLoad={this.iFrameLoaded}
              servicos={this.state.error.response.data.message}
            />
          )}
        </div>
      </div>
    ) : (
      <center>
        <h3>
          Página não existe ou acesso inválido.
          <br />
          <br />
          Esta tentativa foi logada e informada.
        </h3>
      </center>
    );
  }
}

export default ServicePage;
