import React from 'react';
import PropTypes from 'prop-types';
import {Menu} from 'primereact/menu';
import {loadHistorico} from '../apiCalls';
import AuthContext from '../../../../AuthContext';

export const menuStyle = {
  width: '100%',
};

class Dashboard extends React.Component {
  static propTypes = {
    getHistorico: PropTypes.any,
    historico: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      historico: null,
    };
  }

  async componentDidMount() {
    try {
      const response = await loadHistorico(
        this.context.tokenParsed.preferred_username,
      );
      this.setState({historico: response});
    } catch (error) {
      //console.log(error);
    }
  }

  componentWillUnmount() {}

  createMenuConsultas(value) {
    this.menuConsultas = value.map(montamenumodel);
  }
  createMenuCadastros(value) {
    this.menuCadastros = value.map(montamenumodel);
  }
  createMenuRelatorios(value) {
    this.menuRelatorios = value.map(montamenumodel);
  }

  render() {
    const historico = this.state.historico;

    if (
      historico &&
      historico.historicoConsultas &&
      historico.historicoConsultas.length > 0
    ) {
      this.createMenuConsultas(historico.historicoConsultas);
    }
    if (
      historico &&
      historico.historicoCadastros &&
      historico.historicoCadastros.length > 0
    ) {
      this.createMenuCadastros(historico.historicoCadastros);
    }
    if (
      historico &&
      historico.historicoRelatorios &&
      historico.historicoRelatorios.length > 0
    ) {
      this.createMenuRelatorios(historico.historicoRelatorios);
    }

    return (
      <div className="p-grid p-fluid dashboard">
        <div className="p-col-12 p-lg-4">
          <div className="card summary">
            <h1>Cadastros</h1>
            <Menu style={menuStyle} model={this.menuCadastros} />
          </div>
        </div>
        <div className="p-col-12 p-lg-4">
          <div className="card summary">
            <h1>Consultas</h1>
            <Menu style={menuStyle} model={this.menuConsultas} />
          </div>
        </div>
        <div className="p-col-12 p-lg-4">
          <div className="card summary">
            <h1>Relatórios</h1>
            <Menu style={menuStyle} model={this.menuRelatorios} />
          </div>
        </div>
      </div>
    );
  }
}

function montamenumodel(value) {
  let items = value.items && value.items.map(montamenumodel);
  let href =
    value.params !== undefined &&
    value.params !== null &&
    value.params.length > 1
      ? value.command + '?' + value.params
      : value.command;
  return items
    ? {label: value.label, icon: value.icon, items: items}
    : {
        label: value.label,
        icon: value.icon,
        command: () => {
          window.location = href;
        },
      };
}

Dashboard.contextType = AuthContext;

export default Dashboard;
