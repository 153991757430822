import React from 'react';
import Loadable from 'react-loadable';

function Loading(props) {
  if (props.error) {
    if (props.error.response && props.error.response.status === 400) {
      return (
        <center>
          <h3>
            Página não existe ou acesso inválido.
            <br />
            <br />
            Esta tentativa foi logada e informada.
          </h3>
        </center>
      );
    }
    return (
      <div>
        Error! <button onClick={props.retry}>Tente novamente</button>
      </div>
    );
  } else if (props.pastDelay) {
    return <div>Carregando...</div>;
  } else {
    return null;
  }
}

async function checkAccess(app) {
  // await ServicoApi.checkAccess(window.location.hash.substr(1));
  return app();
}

const ComissaoVariavelRelatorio = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/compras/comissao-variavel-relatorio/root'),
    ),
  loading: Loading,
});
const RelatorioPrevisaoVenda = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/compras/relatorio-previsao-venda/root')),
  loading: Loading,
});
const CadastroProdutosCondorSimplus = Loadable({
  loader: () =>
    checkAccess(() =>
      import(
        './apps/geral/produtos/simplus/cadastro-produtos-condor-simplus/root'
      ),
    ),
  loading: Loading,
});
const BloqueioDatasEntregaCd = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/vendas/cadastro/bloqueio-datas-entrega-cd/root'),
    ),
  loading: Loading,
});
const PedidoVendaAtacado = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/vendas/pedido-venda/pedido-venda/root')),
  loading: Loading,
});
const CadastroAtratores = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/cadastro-atratores/src/root'),
    ),
  loading: Loading,
});
const LogisticaCadastroCadastroEquipamentos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/cadastro-equipamentos/src/root'),
    ),
  loading: Loading,
});
const LogisticaCadastroBloqueioAtendimentoProduto = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/bloqueio-atendimento-produto/src/root'),
    ),
  loading: Loading,
});
const LogisticaConsultaRelatorioProdutosExcluidos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/consulta/relatorio-produtos-excluidos/src/root'),
    ),
  loading: Loading,
});
const VendasPostosConsultaVendaPostos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/vendas/postos/consulta-venda-postos/root'),
    ),
  loading: Loading,
});
const VendasPostosConsultaGerencialPostos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/vendas/postos/consulta-gerencial-postos/src/root'),
    ),
  loading: Loading,
});
const RHGeralControleAdvertenciaSuspensao = Loadable({
  loader: () =>
    checkAccess(() =>
      import(
        './apps/rh/geral/controle-advertencia-suspensao/src/pages/ControleAdvertenciaSuspensaoPage'
      ),
    ),
  loading: Loading,
});
const RHGeralCadastroAdvertenciaTipo = Loadable({
  loader: () =>
    checkAccess(() =>
      import(
        './apps/rh/geral/controle-advertencia-suspensao/src/pages/CadastroAdvertenciaTipoPage'
      ),
    ),
  loading: Loading,
});
const LogisticaCadastroCelulas = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/cadastro-celulas/src/root'),
    ),
  loading: Loading,
});
const LogisticaCadastroPicking = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/cadastro-picking/src/root'),
    ),
  loading: Loading,
});
const ComprasPedidoLiberacaoQuantidadeCompra = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/compras/pedido/liberacao-quantidade-compra/root'),
    ),
  loading: Loading,
});
const LogisticaConsultaTempoDescarga = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/consulta/tempo-descarga-ui/src/root'),
    ),
  loading: Loading,
});
const MarketingEnventosConvidados = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/marketing/eventos/eventos-convidados-ui/src/root'),
    ),
  loading: Loading,
});
const RHTerceirosCadastroContrato = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/rh/terceiros/cadastro-contrato-ui/src/root'),
    ),
  loading: Loading,
});
const RHTerceirosCadastroTipoDesconto = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/rh/terceiros/cadastro-tipo-desconto-ui/src/root'),
    ),
  loading: Loading,
});
const RHTerceirosDescontoContrato = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/rh/terceiros/desconto-contrato-ui/src/root'),
    ),
  loading: Loading,
});
const RHTerceirosMovimentosMensais = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/rh/terceiros/movimentos-mensais-descontos-ui/src/root'),
    ),
  loading: Loading,
});
const ComprasCotacoesComparaCotacoes = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/compras/cotacoes/compara-cotacoes-ui/root'),
    ),
  loading: Loading,
});
const FiscalTributosSaldoPISCofins = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/fiscal/tributos/saldo-pis-cofins/src/root'),
    ),
  loading: Loading,
});
const FiscalTributosEfdReinf = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/fiscal/tributos/efd-reinf/src/root')),
  loading: Loading,
});
const LogisticaCadastroGruposFamilia = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/cadastro-grupos-familia/src/root'),
    ),
  loading: Loading,
});
const FinanceiroContaCorrenteManutencaoCliente = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/financeiro/conta-corrente/manutencao-cliente/src/root'),
    ),
  loading: Loading,
});
const VendasCmvConsultaCmv = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/vendas/cmv/consulta-cmv/src/root')),
  loading: Loading,
});
const LogisticaCadastroRelacionaProdutoAtak = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/relaciona-produto-atak/src/root'),
    ),
  loading: Loading,
});
const LogisticaConsultaRessupreRemaneja = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/consulta/ressupre-remaneja/src/root'),
    ),
  loading: Loading,
});
const FiscalNfRelatorioNfe = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/fiscal/nf/relatorio-nfe/src/root')),
  loading: Loading,
});
const FinanceiroTesourariaConciliacaoPagamentos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/financeiro/tesouraria/conciliacao-pagamentos/src/root'),
    ),
  loading: Loading,
});
const FinanceiroTesourariaConciliacaoRecargaUrbs = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/financeiro/tesouraria/conciliacao-recarga-urbs/src/root'),
    ),
  loading: Loading,
});
const FinanceiroTesourariaConferenciaResgateCashback = Loadable({
  loader: () =>
    checkAccess(() =>
      import(
        './apps/financeiro/tesouraria/conferencia-resgate-cashback/src/root'
      ),
    ),
  loading: Loading,
});
// ssh://git@gitlab.condor.com.br:8443/financeiro/tesouraria/cashback-contabiliza-ui.git
const FinanceiroTesourariaCashbackContabiliza = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/financeiro/tesouraria/cashback-contabiliza/src/root'),
    ),
  loading: Loading,
});
// ssh://git@gitlab.condor.com.br:8443/financeiro/tesouraria/configuracoes-contabeis-ui.git
const FinanceiroTesourariaConfiguracoesContabeis = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/financeiro/tesouraria/configuracoes-contabeis/src/root'),
    ),
  loading: Loading,
});
const LogisticaConsultaLogCadastroCelulas = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/consulta/log-cadastro-celulas/src/root'),
    ),
  loading: Loading,
});
const LogisticaCadastroGrupofamiliaFamilia = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/grupofamilia-familiacat/src/root'),
    ),
  loading: Loading,
});
const LogisticaPblRelatorioIndicadores = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/pbl/relatorio-indicadores/src/root'),
    ),
  loading: Loading,
});
const LogisticaAgendamentoLogisticaSituacaoAgenda = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/agendamento/logistica-situacao-agenda/src/root'),
    ),
  loading: Loading,
});
const LogisticaAbastecimentoLojaProcessamentoGrade = Loadable({
  loader: () =>
    checkAccess(() =>
      import(
        './apps/logistica/abastecimento-loja/processamento-grade/src/root'
      ),
    ),
  loading: Loading,
});
const RhGeralRelatorioPromotores = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/rh/geral/relatorio-promotores/src/root')),
  loading: Loading,
});
const LogisticaExpedicaoConsultaRomaneio = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/expedicao/consulta-romaneio/src/root'),
    ),
  loading: Loading,
});
const EstoqueConsultasExtratoItem = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/estoque/consultas/extrato-item/src/root')),
  loading: Loading,
});
const EstoqueConsultasSaldoItensFornecedor = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/estoque/consultas/saldo-itens-fornecedor/src/root'),
    ),
  loading: Loading,
});
const GeralProdutosControleProdutosVencidos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/geral/produtos/controle-de-produtos-vencidos/src/root'),
    ),
  loading: Loading,
});
const GeralProdutosSaneamentoDadosLogistico = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/geral/produtos/saneamento-dados-logistico/src/root'),
    ),
  loading: Loading,
});
const LogisticaCadastroAbastecimentoCdentreposto = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/abastecimento-cd-entreposto/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/financeiro/conta-corrente/relatorio-troco-operador-ui.git
const FinanceiroContaCorrenteRelatorioTrocoOperador = Loadable({
  loader: () =>
    checkAccess(() =>
      import(
        './apps/financeiro/conta-corrente/relatorio-troco-operador/src/root'
      ),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/vendas/relatorios/forma-pagamento-ui.git
const VendasRelatoriosFormaPagamento = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/vendas/relatorios/forma-pagamento/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/vendas/consultas/metas-realizado-ui.git
const VendasConsultasMetasRealizado = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/vendas/consultas/metas-realizado/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/vendas/consultas/estatistica-operador-loja-ui.git
const VendasConsultasEstatisticaOperadorLoja = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/vendas/consultas/estatistica-operador-loja/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/logistica/consulta/estoque-paletes-ui.git
const LogisticaConsultaEstoquePaletes = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/consulta/estoque-paletes/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/thirdparty/senior/associa-fornecedor-ui.git
const ThirdpartySeniorAssociaFornecedor = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/thirdparty/senior/associa-fornecedor/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/logistica/cadastro/produtos-palete-full-ui.git
const LogisticaCadastroProdutosPaleteFull = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/produtos-palete-full/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/logistica/cadastro/cadastro-dados-logisticos-ui.git
const LogisticaCadastroCadastroDadosLogisticos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/cadastro/cadastro-dados-logisticos/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/financeiro/tesouraria/relatorio-vendas-rappi-ui.git
const FinanceiroTesourariaRelatorioVendasRappi = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/financeiro/tesouraria/relatorio-vendas-rappi/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/financeiro/tesouraria/conferencia-troco-clube-condor-ui.git
const FinanceiroTesourariaConferenciaTrocoClubeCondor = Loadable({
  loader: () =>
    checkAccess(() =>
      import(
        './apps/financeiro/tesouraria/conferencia-troco-clube-condor/src/root'
      ),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/rh/geral/reenvio-relogio-catraca-ui.git
const RGeralReenvioRelogioCatraca = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/rh/geral/reenvio-relogio-catraca/src/root'),
    ),
  loading: Loading,
});
//ssh://git@gitlab.condor.com.br:8443/fiscal/nf/nfe-consulta-ui.git
const FiscalNfConsultaNfe = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/fiscal/nf/nfe-consulta/src/root')),
  loading: Loading,
});
const PrecosVendasEtiquetas = Loadable({
  loader: () =>
    checkAccess(() => import('./apps/precos/vendas/etiquetas/src/root')),
  loading: Loading,
});
const GeralProdutosMercadologicosWeb = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/geral/produtos/mercadologicos-web/src/root'),
    ),
  loading: Loading,
});
const GeralProdutosCadastroProdutos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/geral/produtos/cadastro-produtos-ui/src/root'),
    ),
  loading: Loading,
});
const LogisticaExpedicaoListagemVeiculos = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/logistica/expedicao/listagem-veiculos/src/root'),
    ),
  loading: Loading,
});
const FinanceiroTesourariaRelatorioPagamentoSpc = Loadable({
  loader: () =>
    checkAccess(() =>
      import('./apps/financeiro/tesouraria/relatorio-pagamento-spc/src/root'),
    ),
  loading: Loading,
});
const routes = [
  {
    path: '/compras/comissao-variavel-relatorio',
    component: ComissaoVariavelRelatorio,
  },
  {
    path: '/compras/relatorio-previsao-venda',
    component: RelatorioPrevisaoVenda,
  },
  {
    path: '/geral/produtos/simplus/cadastro-produtos-condor-simplus',
    component: CadastroProdutosCondorSimplus,
  },
  {
    path: '/vendas/cadastro/bloqueio-datas-entrega-cd',
    component: BloqueioDatasEntregaCd,
  },
  {
    path: '/vendas/pedido-venda/pedido-venda',
    component: PedidoVendaAtacado,
  },
  {
    path: '/logistica/cadastro/cadastro-atratores',
    component: CadastroAtratores,
  },
  {
    path: '/logistica/cadastro/cadastro-equipamentos',
    component: LogisticaCadastroCadastroEquipamentos,
  },
  {
    path: '/logistica/consulta/relatorio-produtos-excluidos',
    component: LogisticaConsultaRelatorioProdutosExcluidos,
  },
  {
    path: '/vendas/postos/consulta-venda-postos',
    component: VendasPostosConsultaVendaPostos,
  },
  {
    path: '/vendas/postos/consulta-gerencial-postos',
    component: VendasPostosConsultaGerencialPostos,
  },
  {
    path: '/rh/geral/controle-advertencia-suspensao',
    component: RHGeralControleAdvertenciaSuspensao,
  },
  {
    path: '/rh/geral/cadastro-advertencia-tipo',
    component: RHGeralCadastroAdvertenciaTipo,
  },
  {
    path: '/logistica/cadastro/cadastro-celulas',
    component: LogisticaCadastroCelulas,
  },
  {
    path: '/logistica/cadastro/cadastro-picking',
    component: LogisticaCadastroPicking,
  },
  {
    path: '/logistica/cadastro/bloqueio-atendimento-produto',
    component: LogisticaCadastroBloqueioAtendimentoProduto,
  },
  {
    path: '/compra/pedido/liberacao-quantidade-compra',
    component: ComprasPedidoLiberacaoQuantidadeCompra,
  },
  {
    path: '/logistica/consulta/agendamento-tempo-descarga',
    component: LogisticaConsultaTempoDescarga,
  },
  {
    path: '/marketing/tarefas/eventos-convidados',
    component: MarketingEnventosConvidados,
  },
  {
    path: '/rh/terceiros/cadastro-contrato',
    component: RHTerceirosCadastroContrato,
  },
  {
    path: '/rh/terceiros/cadastro-tipo-desconto',
    component: RHTerceirosCadastroTipoDesconto,
  },
  {
    path: '/rh/terceiros/desconto-contrato',
    component: RHTerceirosDescontoContrato,
  },
  {
    path: '/rh/terceiros/movimentos-mensais-descontos',
    component: RHTerceirosMovimentosMensais,
  },
  {
    path: '/compras/cotacoes/compara-cotacoes',
    component: ComprasCotacoesComparaCotacoes,
  },
  {
    path: '/fiscal/tributos/saldo-pis-cofins',
    component: FiscalTributosSaldoPISCofins,
  },
  {
    path: '/fiscal/tributos/efd-reinf',
    component: FiscalTributosEfdReinf,
  },
  {
    path: '/logistica/cadastro/cadastro-grupos-familia',
    component: LogisticaCadastroGruposFamilia,
  },
  {
    path: '/financeiro/conta-corrente/manutencao-cliente',
    component: FinanceiroContaCorrenteManutencaoCliente,
  },
  {
    path: '/vendas/cmv/consulta-cmv',
    component: VendasCmvConsultaCmv,
  },
  {
    path: '/logistica/cadastro/relaciona-produto-atak',
    component: LogisticaCadastroRelacionaProdutoAtak,
  },
  {
    path: '/logistica/consulta/ressupre-remaneja',
    component: LogisticaConsultaRessupreRemaneja,
  },
  {
    path: '/fiscal/nf/relatorio-nfe',
    component: FiscalNfRelatorioNfe,
  },
  {
    path: '/financeiro/tesouraria/conciliacao-pagamentos',
    component: FinanceiroTesourariaConciliacaoPagamentos,
  },
  {
    path: '/financeiro/tesouraria/conciliacao-recarga-urbs',
    component: FinanceiroTesourariaConciliacaoRecargaUrbs,
  },
  {
    path: '/financeiro/tesouraria/conferencia-resgate-cashback',
    component: FinanceiroTesourariaConferenciaResgateCashback,
  },
  {
    path: '/financeiro/tesouraria/cashback-contabil',
    component: FinanceiroTesourariaCashbackContabiliza,
  },
  {
    path: '/financeiro/tesouraria/configuracoes-contabeis',
    component: FinanceiroTesourariaConfiguracoesContabeis,
  },
  {
    path: '/logistica/consulta/log-cadastro-celulas',
    component: LogisticaConsultaLogCadastroCelulas,
  },
  {
    path: '/logistica/cadastro/grupofamilia-familiacat',
    component: LogisticaCadastroGrupofamiliaFamilia,
  },
  {
    path: '/logistica/pbl/relatorio-indicadores',
    component: LogisticaPblRelatorioIndicadores,
  },
  {
    path: '/logistica/agendamento/logistica-situacao-agenda',
    component: LogisticaAgendamentoLogisticaSituacaoAgenda,
  },
  {
    path: '/logistica/abastecimento-loja/processamento-grade',
    component: LogisticaAbastecimentoLojaProcessamentoGrade,
  },
  {
    path: '/rh/geral/relatorio-promotores',
    component: RhGeralRelatorioPromotores,
  },
  {
    path: '/logistica/expedicao/consulta-romaneio',
    component: LogisticaExpedicaoConsultaRomaneio,
  },
  {
    path: '/estoque/consultas/extrato-item',
    component: EstoqueConsultasExtratoItem,
  },
  {
    path: '/estoque/consultas/saldo-itens-fornecedor',
    component: EstoqueConsultasSaldoItensFornecedor,
  },
  {
    path: '/geral/produtos/controle-de-produtos-vencidos',
    component: GeralProdutosControleProdutosVencidos,
  },
  {
    path: '/geral/produtos/saneamento-dados-logistico',
    component: GeralProdutosSaneamentoDadosLogistico,
  },
  {
    path: '/logistica/cadastro/abastecimento-cd-entreposto',
    component: LogisticaCadastroAbastecimentoCdentreposto,
  },
  {
    path: '/financeiro/conta-corrente/relatorio-troco-operador',
    component: FinanceiroContaCorrenteRelatorioTrocoOperador,
  },
  {
    path: '/vendas/relatorios/forma-pagamento',
    component: VendasRelatoriosFormaPagamento,
  },
  {
    path: '/vendas/consultas/metas-realizado',
    component: VendasConsultasMetasRealizado,
  },
  {
    path: '/vendas/consultas/estatistica-operador-loja',
    component: VendasConsultasEstatisticaOperadorLoja,
  },
  {
    path: '/logistica/consulta/estoque-paletes',
    component: LogisticaConsultaEstoquePaletes,
  },
  {
    path: '/thirdparty/senior/associa-fornecedor',
    component: ThirdpartySeniorAssociaFornecedor,
  },
  {
    path: '/logistica/cadastro/produtos-palete-full',
    component: LogisticaCadastroProdutosPaleteFull,
  },
  {
    path: '/logistica/cadastro/cadastro-dados-logisticos',
    component: LogisticaCadastroCadastroDadosLogisticos,
  },
  {
    path: '/financeiro/tesouraria/relatorio-vendas-rappi',
    component: FinanceiroTesourariaRelatorioVendasRappi,
  },
  {
    path: '/financeiro/tesouraria/conferencia-troco-clube-condor',
    component: FinanceiroTesourariaConferenciaTrocoClubeCondor,
  },
  {
    path: '/rh/geral/reenvio-relogio-catraca',
    component: RGeralReenvioRelogioCatraca,
  },
  {
    path: '/fiscal/nf/consulta-nfe',
    component: FiscalNfConsultaNfe,
  },
  {
    path: '/precos/vendas/etiquetas',
    component: PrecosVendasEtiquetas,
  },
  {
    path: '/geral/produtos/mercadologicos-web',
    component: GeralProdutosMercadologicosWeb,
  },
  {
    path: '/geral/produtos/cadastro-produtos',
    component: GeralProdutosCadastroProdutos,
  },
  {
    path: '/logistica/expedicao/listagem-veiculos',
    component: LogisticaExpedicaoListagemVeiculos,
  },
  {
    path: '/financeiro/tesouraria/relatorio-pagamento-spc',
    component: FinanceiroTesourariaRelatorioPagamentoSpc,
  },
];

export default routes;
