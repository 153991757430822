import React from 'react';
import PropTypes from 'prop-types';
import ProfileArea from './ProfileArea';
import ChangePassword from './ChangePassword';
import {loadPerfil} from '../apiCalls';
import AuthContext from '../../../../AuthContext';

class ProfilePage extends React.Component {
  static propTypes = {
    perfil: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      perfil: null,
    };
  }

  async componentDidMount() {
    try {
      const response = await loadPerfil(
        this.context.tokenParsed.preferred_username,
      );
      this.setState({perfil: response});
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <div className="p-grid">
        <div className="p-col-12">
          {this.state.perfil && <ProfileArea perfil={this.state.perfil} />}
          {this.state.perfil && <ChangePassword />}
        </div>
      </div>
    );
  }
}

ProfilePage.contextType = AuthContext;

export default ProfilePage;
