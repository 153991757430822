import axios from 'axios';
import * as Const from '../../../consts/constants';

const API_URL = '/api-rc/seguranca/permissao/menucondor/menu';

class MenuApi {
  static getMenu() {
    return new Promise((resolve, reject) =>
      axios
        .get(API_URL)
        .then(response => resolve(response.data))
        .catch(err => reject(err)),
    );
  }

  static getRotas() {
    return new Promise((resolve, reject) =>
      axios
        .get(Const.API_CHUNKS_URI)
        .then(response => resolve(response.data))
        .catch(err => reject(err)),
    );
  }
}

export default MenuApi;
