import React from 'react';

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <div className="splash-container">
        <div className="splash-double-bounce1"></div>
        <div className="splash-double-bounce2"></div>
      </div>
    </div>
  );
};

export default SplashScreen;
