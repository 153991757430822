import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import AppTopbar from './AppTopbar';
import AppMenu from './AppMenu';
import AppInlineProfile from './AppInlineProfile';
import {loadMenu, loadRotas} from '../apiCalls';
import {AllRoutes} from './AllRoutes';
import {isAtacadista} from '../../../..';

class App extends Component {
  static propTypes = {
    menus: PropTypes.any,
    rotas: PropTypes.any,
    loadMenu: PropTypes.any,
    authToken: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      layoutMode: 'static',
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menus: null,
      rotas: null,
      hasError: false,
      errorMsg: null,
      errorInfo: null,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }

  onWrapperClick() {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === 'overlay') {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === 'static') {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick() {
    this.menuClick = true;
    setTimeout(() => {
      this.layoutMenuScroller.moveBar();
    }, 500);
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += ' ' + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          '(^|\\b)' + className.split(' ').join('|') + '(\\b|$)',
          'gi',
        ),
        ' ',
      );
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidCatch(error, info) {
    this.setState({hasError: true, errorMsg: error, errorInfo: info});
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, 'body-overflow-hidden');
    else this.removeClass(document.body, 'body-overflow-hidden');
  }

  async componentDidMount() {
    try {
      const response = await loadMenu();
      this.setState({menus: response});
      const rotasResponse = await loadRotas();
      this.setState({rotas: rotasResponse});
    } catch (error) {
      this.setState({
        hasError: true,
        errorMsg: 'Erro ao carregar menu.',
        errorInfo: error,
      });
    }
  }

  render() {
    let body;
    let logo = isAtacadista()
      ? 'assets/layout/images/logo-gigante.png'
      : 'assets/layout/images/logo.svg';

    let wrapperClass = classNames('layout-wrapper', {
      'layout-overlay': this.state.layoutMode === 'overlay',
      'layout-static': this.state.layoutMode === 'static',
      'layout-static-sidebar-inactive':
        this.state.staticMenuInactive && this.state.layoutMode === 'static',
      'layout-overlay-sidebar-active':
        this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
      'layout-mobile-sidebar-active': this.state.mobileMenuActive,
    });
    let sidebarClassName = classNames('layout-sidebar', {
      'layout-sidebar-dark': false,
    });

    if (this.state.hasError) {
      // You can render any custom fallback UI
      body = (
        <div>
          <h1>Ocorreu um erro na chamada da aplicação.</h1>
          <p>
            Por favor espere alguns minutos, caso o problema persista favor
            entrar em contato com a TI.
          </p>
          <div style={{display: 'none'}}>
            {'Error Message: ' + this.state.errorMsg}
          </div>
          <div style={{display: 'none'}}>
            {'Error Info: ' + JSON.stringify(this.state.errorInfo)}
          </div>
        </div>
      );
    } else {
      if (this.state.menus) {
        this.menu = this.state.menus.map(montamenumodel);
      }
      body = <AllRoutes />;
    }

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <AppTopbar onToggleMenu={this.onToggleMenu} />

        <div
          ref={el => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}>
          <ScrollPanel
            ref={el => (this.layoutMenuScroller = el)}
            style={{height: '100%'}}>
            <div className="layout-sidebar-scroll-content">
              <div className="layout-logo">
                <img alt="Logo" src={logo} />
              </div>
              <AppInlineProfile />
              <AppMenu
                model={this.menu}
                onMenuItemClick={this.onMenuItemClick}
              />
            </div>
          </ScrollPanel>
        </div>

        <div className="layout-main">{body}</div>

        <div className="layout-mask"></div>
      </div>
    );
  }
}

function montamenumodel(value) {
  let items = value.items && value.items.map(montamenumodel);
  let href =
    value.params !== undefined &&
    value.params !== null &&
    value.params.length > 1
      ? value.command + '?' + value.params
      : value.command;
  let vui = href !== null && href.includes('vui=true') ? true : false;
  return items
    ? {label: value.label, icon: value.icon, items: items}
    : !vui
    ? {
        label: value.label,
        icon: value.icon,
        command: () => {
          window.location = href;
        },
      }
    : {
        label: value.label,
        icon: value.icon,
        url: href,
      };
}

export default App;
