import React from 'react';
import PropTypes from 'prop-types';
import {Formik, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import {Messages} from 'primereact/messages';
import {changePassword} from '../apiCalls';

const initialFormValues = {
  newPasswordValue: '',
};

const validationSchema = Yup.object().shape({
  newPasswordValue: Yup.string()
    .min(4, 'Deve ser maior que 4 caracteres')
    .max(200, 'Máximo de 200 caracteres')
    .required('Nova senha é obrigatória!'),
});

const validate = () => {
  const errors = {};
  return errors;
};

class ChangePassword extends React.Component {
  static propTypes = {
    erroMessage: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      erroMessage: null,
    };
    this.showSuccess = this.showSuccess.bind(this);
    this.showError = this.showError.bind(this);
  }

  showSuccess() {
    this.messages.show({
      severity: 'success',
      summary: 'Sucesso',
      detail: 'Senha alterada',
      life: 5000,
    });
  }

  showError(error) {
    this.messages.show({
      severity: 'error',
      summary: error.data.title,
      detail: error.data.detail,
      life: 5000,
    });
  }

  render() {
    return (
      <div className="card card-w-title">
        <h1>Troca de senha</h1>
        <Messages ref={el => (this.messages = el)} />
        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          validate={validate}
          onSubmit={async (values, actions) => {
            try {
              const response = await changePassword(values);
              this.setState({erroMessage: response});
              actions.resetForm(initialFormValues);
              actions.setSubmitting(false);
              actions.setStatus({success: true});
              this.showSuccess();
            } catch (error) {
              actions.setStatus({success: false});
              actions.setSubmitting(false);
              this.showError(error.response);
            }
          }}
          render={props => (
            <Form>
              <br />
              <div className="p-grid">
                <div className="p-col-12">
                  <div>Nova senha</div>
                  <div>
                    <Password
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.newPasswordValue}
                      name="newPasswordValue"
                      promptLabel="Informe a nova senha"
                      autoComplete="new-password"
                      feedback={false}
                    />
                    <div style={{color: 'red'}}>
                      <ErrorMessage name="newPasswordValue" />
                    </div>
                  </div>
                </div>
                <div className="p-g">
                  <div className="p-g-12 p-md-4" style={{textAlign: 'center'}}>
                    <Button
                      label="Atualiza"
                      disabled={props.isSubmitting || !props.dirty}
                      type="submit"
                      style={{marginBottom: '10px'}}
                    />
                    <Button
                      label="Reset"
                      onClick={props.handleReset}
                      className="p-button-secondary"
                      style={{marginBottom: '10px'}}
                    />
                  </div>
                </div>
              </div>
              {/* <Debug /> */}
            </Form>
          )}
        />
      </div>
    );
  }
}

export default ChangePassword;
