import axios from 'axios';

const API_URL = '/api-rc/seguranca/permissao/perfil';

class PerfilApi {
  static getPerfil() {
    return new Promise((resolve, reject) =>
      axios
        .get(`${API_URL}`)
        .then(response => resolve(response.data))
        .catch(err => reject(err)),
    );
  }

  static changePassword(values) {
    return new Promise((resolve, reject) =>
      axios
        .post(`${API_URL}/changePassword`, {
          newPassword: values.newPasswordValue,
        })
        .then(function(response) {
          resolve(response.data);
        })
        .catch(function(error) {
          reject(error);
        }),
    );
  }
}

export default PerfilApi;
