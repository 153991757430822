import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Message } from 'primereact/message';

class ErrorPage extends Component {

    static propTypes = {
      id: PropTypes.string,
      onLoad: PropTypes.func,
      servicos: PropTypes.any
    }

    render() {
      return (
        <div className='ui-g exception-panel'>
          <div className='ui-g-12 ui-md-12'>
            <Message severity="error" text={this.props.servicos.errorMessage} />
          </div>
        </div>
      );
    }
}

export default ErrorPage;
