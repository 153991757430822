import axios from 'axios';

const API_URL = '/api-rc/seguranca/permissao/servico/info';
const API_CHECK_URL = '/api-rc/seguranca/permissao/servico/access';

class ServicoApi {
  static getServico(id) {
    return new Promise((resolve, reject) =>
      axios
        .get(`${API_URL}/${id}`)
        .then(function(response) {
          resolve(response.data);
        })
        .catch(function(error) {
          reject(error);
        }),
    );
  }

  static checkAccess(id) {
    return axios.post(`${API_CHECK_URL}`, null, {headers: {uriservico: id}});
    // return new Promise((resolve, reject) =>
    //   axios
    //     .post(`${API_CHECK_URL}`, null, {headers: {uriservico: id}})
    //     .then(response => {
    //       console.log('999999999');
    //       resolve(response.data);
    //     })
    //     .catch(error => {
    //       console.log('88888888888');
    //       reject(error);
    //     }),
    // );
  }
}

export default ServicoApi;
