import MenuApi from '../apis/menuApi';
import PerfilApi from '../apis/perfilApi';
import HistoricoApi from '../apis/historicoApi';

export const loadMenu = async () => {
  const res = await MenuApi.getMenu();
  return res;
};

export const loadRotas = async () => {
  const res = await MenuApi.getRotas();
  return res;
};

export const loadPerfil = async loginName => {
  const res = await PerfilApi.getPerfil(loginName);
  return res;
};

export const changePassword = async values => {
  const res = await PerfilApi.changePassword(values);
  console.log(res);
  return res;
};

export const loadHistorico = async loginName => {
  const res = await HistoricoApi.getHistorico(loginName);
  return res;
};
