/* eslint-disable react/prop-types */
import React, {Component} from 'react';
import {Route, Redirect} from 'react-router';

import AuthContext from '../../../../AuthContext';
import ServicoApi from '../../apis/servicoApi';

export class ProtectedRoute extends Component {
  constructor(props) {
    super();
    this.state = {
      isLoading: true,
      isOk: false,
      path: props.path,
    };
  }

  async componentDidMount() {
    try {
      await ServicoApi.checkAccess(this.state.path);
      this.setState({isOk: true, isLoading: false});
    } catch (error) {
      this.setState({isOk: false, isLoading: false});
    }
  }

  render() {
    const {component: Component, ...props} = this.props;

    return this.state.isLoading ? (
      <center>
        <h3>Carregando...</h3>
      </center>
    ) : this.state.isOk ? (
      <Route
        {...props}
        render={props => {
          return this.context.tokenParsed ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: {from: props.location},
              }}
            />
          );
        }}
      />
    ) : (
      <center>
        <h3>
          Página não existe ou acesso inválido.
          <br />
          <br />
          Esta tentativa foi logada e informada.
        </h3>
      </center>
    );
  }
}

ProtectedRoute.contextType = AuthContext;
